import { ReactNode, useState } from 'react';

import { Layout, scale, typography } from '@scripts/gds';

export const ExpandableBlock = ({
    title,
    children,
    height = scale(9),
}: {
    title?: string;
    children: ReactNode;
    height?: number;
}) => {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <Layout gap={0} cols={1}>
            {title && (
                <Layout.Item css={{ marginBottom: scale(3) }}>
                    <h2 css={typography('h3')}>{title}</h2>
                </Layout.Item>
            )}

            <Layout.Item
                css={{
                    position: 'relative',
                    ...(isExpanded && { marginBottom: scale(2) }),
                    ...(!isExpanded && { overflow: 'hidden', maxHeight: `${height}px` }),
                }}
            >
                {children}
                <div
                    css={{
                        position: 'absolute',
                        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%)',
                        bottom: 0,
                        left: 0,
                        height: scale(10),
                        width: '100%',
                        display: isExpanded ? 'none' : 'initial',
                    }}
                />
            </Layout.Item>
        </Layout>
    );
};
