import { type GetServerSidePropsContext, type GetServerSidePropsResult } from 'next';
import { QueryClient } from 'react-query';
import { parse, serialize } from 'cookie';
import { APIClient } from '@api/index';
import { getHost } from '@scripts/helpers';
import { TokenDataProps } from '@context/auth';
import ControlViewItems from '@components/ControlViewItems';
import { defaultCookieOptions, MILISECONDS_IN_SECOND } from '@scripts/constants';
import { ISSRPageProps } from './types';
import { setCommonPageData } from './setCommonPageData';

/**
 * Получить общие для всех страниц данные
 */
export const getCommonProps = async <TProps extends ISSRPageProps>(
    context: GetServerSidePropsContext,
    fn: (params: any, queryClient: QueryClient, apiClient: APIClient) => Promise<GetServerSidePropsResult<TProps>>
) => {
    const resultingProps: Record<string, any> = {};

    try {
        const { req, res } = context;

        const cookies = req.headers.cookie ? parse(req.headers.cookie) : {};

        const currentCity = cookies.city;

        let token: TokenDataProps | undefined = cookies
            ? {
                  accessToken: cookies.access_token,
                  hasRefreshToken: Boolean(cookies.refresh_token),
                  expiresAt: cookies.expires_at,
              }
            : undefined;

        const setToken = (newTokens: TokenDataProps) => {
            token = newTokens;
            res.setHeader('Set-Cookie', [
                serialize('access_token', token.accessToken || '', {
                    maxAge: 0,
                    ...defaultCookieOptions,
                }),
                serialize('refresh_token', cookies.refresh_token || '', {
                    maxAge: 0,
                    ...defaultCookieOptions,
                }),
                serialize('expires_at', token.expiresAt || '', {
                    maxAge: 0,
                    ...defaultCookieOptions,
                }),
            ]);
        };

        const apiClient = new APIClient(`${getHost()}/api/v1/`, token, setToken);
        const isSSR = !req.url?.includes('/_next/data/')!;
        resultingProps.isSSR = isSSR;

        const queryClient = new QueryClient();
        if (isSSR) {
            setCommonPageData(queryClient, apiClient);
        }

        const result = await fn(
            {
                isSSR,
                hasSSRError: false,
                ...(!!token?.accessToken && { accessToken: token?.accessToken }),
                ...(!!token?.hasRefreshToken && {
                    refreshToken: token?.hasRefreshToken,
                }),
                ...(!!token?.expiresAt && { expiresAt: token?.expiresAt }),
                ...(!!currentCity && { currentCity: currentCity }),
            },
            queryClient,
            apiClient
        );

        return result;
    } catch (err) {
        console.error('SSR error:', err, 'resultingProps=', resultingProps);

        return Promise.resolve({
            props: {
                ...resultingProps,
                statusCode: 500,
                err: JSON.stringify(err),
                hasSSRError: true,
            },
            notFound: true,
        } as never as GetServerSidePropsResult<TProps>);
    }
};
